









import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class VHelpIcon extends Vue {
  @Prop({ default: '' }) private text!: string;
  @Prop({ required: true }) private id!: string;
}
